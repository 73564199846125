import React from "react";
import styles from "./FooterContainer.module.scss";

const FooterContainer = () => {
  return (
    <div className={`${styles.footer} jumbotron text-center`}>
      <h5>Got questions?</h5>
      <p>
        We're here to help. Contact our friendly staff on
        <br />
        <a href="tel:0800005797">0800 005 797</a> or{" "}
        <a href="mailto:partners@prospa.co.nz">partners@prospa.co.nz</a>
      </p>
    </div>
  );
};

export default FooterContainer;
