import React from "react";
import MultipleSelect, {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  MultiValueProps,
} from "react-select";
import { Button, FormGroup, Label, Input, FormFeedback } from "reactstrap";

import arrow from "@prospa/icons/dist/svg/ChevronDown.svg";
import close from "@prospa/icons/dist/svg/Close.svg";

import * as Validator from "../../services/FormValidation";

import { selectStyle, errorStyle } from "./ReactSelectStyle";
import { fireGtmEvent } from "../../services/PageService";
import Select from "../Select";
import "./ProfilePage.scss";

export interface ProfilePageProps {
  handleClick: Function;
  goBack: Function;
  pageState?: ProfilePageState;
}

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <img src={arrow} alt="drop down indicator" width={20} />
      </components.DropdownIndicator>
    )
  );
};

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <img src={close} alt="close indicator" width={16} />
      </components.ClearIndicator>
    )
  );
};

const MultiValueRemove = (props: MultiValueProps) => {
  return (
    components.MultiValueRemove && (
      <components.MultiValueRemove {...props}>
        <img src={close} alt="close indicator" width={16} />
      </components.MultiValueRemove>
    )
  );
};

interface Option {
  [key: string]: any;
}

export interface ProfilePageState {
  operationYears?: string;
  staffNumber?: string;
  activeCustomers?: string;
  targetMarket?: Option[];
  mainProduct?: Option[];
  otherProduct?: string;
  businessGeneration?: Option[];
  otherBusiness?: string;
  customerComms?: Option[];
  otherComms?: string;
}

interface pageValidations {
  isYearsProvided?: boolean;
  isStaffProvided?: boolean;
  isCustomerProvided?: boolean;
  isTargetProvided?: boolean;
  isProductProvided?: boolean;
  isBusinessProvided?: boolean;
  isCommsProvided?: boolean;
}

class ProfilePage extends React.Component<ProfilePageProps, ProfilePageState> {
  constructor(props: ProfilePageProps) {
    super(props);
    this.state = this.props.pageState || {
      operationYears: "",
      staffNumber: "",
      activeCustomers: "",
      targetMarket: [],
      mainProduct: [],
      otherProduct: "",
      businessGeneration: [],
      otherBusiness: "",
      customerComms: [],
      otherComms: "",
    };
  }

  validations: pageValidations = {
    isYearsProvided: true,
    isStaffProvided: true,
    isCustomerProvided: true,
    isTargetProvided: true,
    isProductProvided: true,
    isBusinessProvided: true,
    isCommsProvided: true,
  };

  targetMarketOptions = [
    { value: "Consumer", label: "Consumer" },
    { value: "CommercialSME", label: "Commercial - SME" },
    { value: "Commercial", label: "Commercial" },
  ];
  mainProductOptions = [
    { value: "ConsumerMortgage", label: "Consumer Mortgage" },
    { value: "CommercialMortgage", label: "Commercial Mortgage" },
    { value: "AssetFinance", label: "Asset Finance" },
    { value: "DebtorInvoiceFinance", label: "Debtor/Invoice Finance" },
    { value: "BusinessCashFlow", label: "Business Cash Flow" },
  ];
  generateBusinessOptions = [
    { value: "OnlineDigital", label: "Online (digital)" },
    { value: "EmailEdmBusiness", label: "Email (eDM)" },
    { value: "DirectMailDmBusiness", label: "Direct Mail (DM)" },
    { value: "Referrals", label: "Referrals" },
  ];
  customerCommsOptions = [
    { value: "EmailEdmCommunication", label: "Email (eDM)" },
    { value: "DirectMailDmCommunication", label: "Direct Mail (DM)" },
    { value: "Telemarketing", label: "Phone" },
  ];

  componentDidMount = () => {
    fireGtmEvent("profile");
  };

  getMultiSelectClassName = (isValid?: boolean) => {
    return isValid ? selectStyle : errorStyle;
  };

  handleUserInput = (e: any) => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState({ [name]: value });
    this.validateFields(name, value);
  };

  handleMultiChange = (name: string, e: any) => {
    switch (name) {
      case "targetMarket":
        this.setState({ targetMarket: e });
        break;
      case "mainProduct":
        this.setState({ mainProduct: e });
        break;
      case "businessGeneration":
        this.setState({ businessGeneration: e });
        break;
      case "customerComms":
        this.setState({ customerComms: e });
        break;
    }
    this.validateFields(name, e);
  };

  handleBlur = (e: any) => {
    const name = e.target.id;
    const value = e.target.value;
    this.validateFields(name, value);
  };

  handleMultiBlur = (name: string, e: any) => {
    this.validateFields(name, "");
  };

  handleValidations = (e: any) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.handleClick(this.state);
    } else {
      this.forceUpdate();
    }
  };

  validateFields = (name: string, value?: any) => {
    switch (name) {
      case "operationYears":
        this.validations.isYearsProvided = Validator.isPresent(value || this.state.operationYears);
        break;
      case "staffNumber":
        this.validations.isStaffProvided = Validator.isPresent(value || this.state.staffNumber);
        break;
      case "activeCustomers":
        this.validations.isCustomerProvided = Validator.isPresent(
          value || this.state.activeCustomers,
        );
        break;
      case "targetMarket":
        this.validations.isTargetProvided = Validator.isArrayPopulated(
          value || this.state.targetMarket,
        );
        break;
      case "mainProduct":
      case "otherProduct":
        this.validations.isProductProvided =
          Validator.isArrayPopulated(value || this.state.mainProduct) ||
          Validator.isPresent(this.state.otherProduct);
        break;
      case "businessGeneration":
      case "otherBusiness":
        this.validations.isBusinessProvided =
          Validator.isArrayPopulated(value || this.state.businessGeneration) ||
          Validator.isPresent(this.state.otherBusiness);
        break;
      case "customerComms":
      case "otherComms":
        this.validations.isCommsProvided =
          Validator.isArrayPopulated(value || this.state.customerComms) ||
          Validator.isPresent(this.state.otherComms);
        break;
    }
    this.forceUpdate();
  };

  validate = () => {
    this.validateFields("operationYears");
    this.validateFields("staffNumber");
    this.validateFields("activeCustomers");
    this.validateFields("targetMarket");
    this.validateFields("mainProduct");
    this.validateFields("businessGeneration");
    this.validateFields("customerComms");

    return (
      this.validations.isYearsProvided &&
      this.validations.isStaffProvided &&
      this.validations.isCustomerProvided &&
      this.validations.isTargetProvided &&
      this.validations.isProductProvided &&
      this.validations.isBusinessProvided &&
      this.validations.isCommsProvided
    );
  };

  goBack = () => {
    this.props.goBack(this.state);
  };

  render() {
    return (
      <fieldset className="profile-page">
        <legend className="profile-page__title">Your profile</legend>
        <div className="row">
          <FormGroup className="col-md-6">
            <Label for="operationYears">
              Years in operation <span className="required">*</span>
            </Label>
            <Select
              id="operationYears"
              invalid={!this.validations.isYearsProvided}
              className={Validator.getSelectClass(this.state.operationYears)}
              value={this.state.operationYears}
              onChange={this.handleUserInput}
              onBlur={this.handleBlur}
              errorMessage="Years in operation is required"
            >
              <option value="" disabled>
                Please select
              </option>
              <option value="<1">Less than 1</option>
              <option value="1 to 3">1 to 3</option>
              <option value="3 to 6">3 to 6</option>
              <option value="6+">More than 6</option>
            </Select>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="staffNumber">
              Number of staff members <span className="required">*</span>
            </Label>
            <Select
              id="staffNumber"
              invalid={!this.validations.isStaffProvided}
              className={Validator.getSelectClass(this.state.staffNumber)}
              value={this.state.staffNumber}
              onChange={this.handleUserInput}
              onBlur={this.handleBlur}
              errorMessage="Number of staff is required"
            >
              <option value="" disabled>
                Please select
              </option>
              <option value="1">1</option>
              <option value="2 to 3">2 to 3</option>
              <option value="3 to 10">3 to 10</option>
              <option value="10+">More than 10</option>
            </Select>
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-md-6">
            <Label for="activeCustomers">
              Active customers <span className="required">*</span>
            </Label>
            <Select
              id="activeCustomers"
              invalid={!this.validations.isCustomerProvided}
              className={Validator.getSelectClass(this.state.activeCustomers)}
              value={this.state.activeCustomers}
              onChange={this.handleUserInput}
              onBlur={this.handleBlur}
              errorMessage="Number of active customers is required"
            >
              <option value="" disabled>
                Please select
              </option>
              <option value="<200">Less than 200</option>
              <option value="200 to 500">200 to 500</option>
              <option value="500 to 2500">500 to 2500</option>
              <option value="2500+">More than 2500</option>
            </Select>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="targetMarket">
              Target market <span className="required">*</span>
            </Label>
            <MultipleSelect
              id="targetMarket"
              name="targetMarket"
              options={this.targetMarketOptions}
              isMulti
              styles={this.getMultiSelectClassName(this.validations.isTargetProvided)}
              onChange={this.handleMultiChange.bind(this, "targetMarket")}
              placeholder="Select one or multiple"
              onBlur={this.handleMultiBlur.bind(this, "targetMarket")}
              value={this.state.targetMarket}
              closeMenuOnSelect={false}
              components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
            />
            <FormFeedback style={this.validations.isTargetProvided ? {} : { display: "block" }}>
              Target market is required
            </FormFeedback>
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-md-6">
            <Label for="mainProduct">
              Main product offering <span className="required">*</span>
            </Label>
            <MultipleSelect
              id="mainProduct"
              options={this.mainProductOptions}
              isMulti
              styles={this.getMultiSelectClassName(this.validations.isProductProvided)}
              onChange={this.handleMultiChange.bind(this, "mainProduct")}
              placeholder="Select one or multiple"
              onBlur={this.handleMultiBlur.bind(this, "mainProduct")}
              value={this.state.mainProduct}
              closeMenuOnSelect={false}
              components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
            />
            <FormFeedback style={this.validations.isProductProvided ? {} : { display: "block" }}>
              Main product offering(s) is required
            </FormFeedback>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="otherProduct">Other main product offering</Label>
            <Input
              required
              type="text"
              id="otherProduct"
              invalid={!this.validations.isProductProvided}
              className="form-control"
              placeholder="If other, please specify"
              value={this.state.otherProduct}
              onChange={this.handleUserInput}
            />
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-md-6">
            <Label for="businessGeneration">
              Ways you generate business <span className="required">*</span>
            </Label>
            <MultipleSelect
              id="businessGeneration"
              options={this.generateBusinessOptions}
              isMulti
              styles={this.getMultiSelectClassName(this.validations.isBusinessProvided)}
              onChange={this.handleMultiChange.bind(this, "businessGeneration")}
              placeholder="Select one or multiple"
              onBlur={this.handleMultiBlur.bind(this, "businessGeneration")}
              value={this.state.businessGeneration}
              closeMenuOnSelect={false}
              components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
            />
            <FormFeedback style={this.validations.isBusinessProvided ? {} : { display: "block" }}>
              Please provide how you generate business
            </FormFeedback>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="otherBusiness">Other ways you generate business</Label>
            <Input
              required
              type="text"
              id="otherBusiness"
              invalid={!this.validations.isBusinessProvided}
              className="form-control"
              placeholder="If other, please specify"
              value={this.state.otherBusiness}
              onChange={this.handleUserInput}
            />
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-md-6">
            <Label for="customerComms">
              Customer communication method <span className="required">*</span>
            </Label>
            <MultipleSelect
              id="customerComms"
              options={this.customerCommsOptions}
              isMulti
              styles={this.getMultiSelectClassName(this.validations.isCommsProvided)}
              onChange={this.handleMultiChange.bind(this, "customerComms")}
              placeholder="Select one or multiple"
              onBlur={this.handleMultiBlur.bind(this, "customerComms")}
              value={this.state.customerComms}
              closeMenuOnSelect={false}
              components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
            />
            <FormFeedback style={this.validations.isCommsProvided ? {} : { display: "block" }}>
              Please provide how you communicate with customers
            </FormFeedback>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="otherComms">Other communication method</Label>
            <Input
              required
              type="text"
              id="otherComms"
              invalid={!this.validations.isCommsProvided}
              className="form-control"
              placeholder="If other, please specify"
              value={this.state.otherComms}
              onChange={this.handleUserInput}
            />
          </FormGroup>
        </div>

        <FormGroup className="profile-page__navigation-buttons">
          <Button
            className="float-right prev-btn"
            color="secondary"
            onClick={this.goBack}
            data-testid="previous"
          >
            Previous
          </Button>
          <Button
            className="btn btn-primary float-right"
            color="primary"
            onClick={this.handleValidations}
            data-testid="continue"
          >
            Continue
          </Button>
        </FormGroup>
      </fieldset>
    );
  }
}

export default ProfilePage;
