import React from "react";
import { Button, CustomInput, FormGroup, Label, Input, FormFeedback } from "reactstrap";

import { AddressFinder } from "../AddressFinderComponent";

import "./BusinessPage.scss";
import * as Validator from "../../services/FormValidation";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import { fireGtmEvent } from "../../services/PageService";

export interface BusinessPageProps {
  handleClick: Function;
  goBack: Function;
  pageState?: BusinessPageState;
  isOverseasPartner: boolean;
}

export interface BusinessPageState {
  displayAbn?: string;
  acn?: string;
  abn?: string;
  businessName?: string;
  tradingName?: string;
  businessStructure?: string;
  tradingAddress?: AddressStructure;
  isPostalAddressDifferent?: boolean;
  postalAddress?: AddressStructure;
  website?: string;
  gstRegistered?: boolean;
  acl?: string;
  hasAcl?: boolean;
  provideConsent?: boolean;
}

export interface AddressStructure {
  Street?: string;
  City?: string;
  State?: string;
  PostalCode?: string;
  Country?: string;
  label: string;
}

interface pageValidations {
  isAbnValid?: boolean;
  consentGiven?: boolean;
  isAbnValidated: boolean;
  isBusinessNameValid?: boolean;
  isTradingNameValid?: boolean;
  isTradingAddressValid?: boolean;
  isPostalAddressValid?: boolean;
  isAbnTouched?: boolean;
  isAclValid?: boolean;
}

class BusinessPage extends React.Component<BusinessPageProps, BusinessPageState> {
  constructor(props: BusinessPageProps) {
    super(props);

    const pageState = this.props.pageState || {
      displayAbn: "", //display only
      acn: "",
      abn: "",
      businessName: "",
      tradingName: "",
      tradingAddress: { label: "" },
      isPostalAddressDifferent: false,
      postalAddress: { label: "" },
      website: "",
      gstRegistered: true,
      acl: "",
      hasAcl: false,
      provideConsent: false,
    };
    Object.assign(pageState, { provideConsent: false });

    this.state = pageState;

    if (pageState.displayAbn !== "") this.inlineValidate("displayAbn", pageState.displayAbn);
  }

  validations: pageValidations = {
    isAbnValid: true,
    consentGiven: true,
    isAbnValidated: false,
    isBusinessNameValid: true,
    isTradingNameValid: true,
    isTradingAddressValid: true,
    isPostalAddressValid: true,
    isAbnTouched: false,
    isAclValid: true,
  };

  componentDidMount = () => {
    fireGtmEvent("business");
  };

  handleUserInput = (e: any) => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState({ [name]: value });

    if (name === "displayAbn") {
      if (Validator.validateAbn(value)) this.validations.isAbnTouched = true;
      this.validateAbn(value);
      this.forceUpdate();
    } else if (name === "acl") {
      this.inlineValidate(name, value);
      this.forceUpdate();
    }
  };

  inlineValidate = (name: string, value: string) => {
    switch (name) {
      case "displayAbn":
        if (this.props.isOverseasPartner && !this.state.displayAbn) {
          this.validations.isAbnValid = true;
        } else {
          this.validateAbn(value);
        }
        break;

      case "businessName":
        this.validations.isBusinessNameValid = Validator.isPresent(value);
        break;

      case "tradingName":
        this.validations.isTradingNameValid = Validator.isPresent(value);
        break;

      case "tradingAddress":
        this.validations.isTradingAddressValid =
          this.props.isOverseasPartner || Validator.isPresent(value);
        break;

      case "postalAddress":
        this.validations.isPostalAddressValid = Validator.isPresent(value);
        break;

      case "acl":
        this.validations.isAclValid = value.length === 0 || Validator.isNumberPresent(value);
    }
  };

  handleCheck = (e: any) => {
    const name = e.target.id;
    const value = e.target.checked;
    this.setState({ [name]: value });

    if (name === "provideConsent") this.validations.consentGiven = value;
    this.forceUpdate();
  };

  handleGst = (value: boolean) => {
    this.setState({ gstRegistered: value });
  };

  handleValidations = (e: any) => {
    e.preventDefault();
    if (this.validate()) {
      const pageState = this.state;
      this.props.handleClick(pageState);
    } else {
      this.forceUpdate();
    }
  };

  validate = () => {
    this.inlineValidate("displayAbn", this.state.displayAbn);
    this.validations.consentGiven = this.state.provideConsent;
    this.validations.isAbnTouched = true;
    this.inlineValidate("businessName", this.state.businessName);
    this.inlineValidate("tradingName", this.state.tradingName);
    this.inlineValidate("tradingAddress", this.state.tradingAddress.label);
    this.inlineValidate("postalAddress", this.state.postalAddress.label);
    this.validations.isPostalAddressValid = this.state.isPostalAddressDifferent
      ? this.validations.isPostalAddressValid
      : true;
    this.inlineValidate("acl", this.state.acl);

    return (
      this.validations.consentGiven &&
      this.validations.isAbnValid &&
      this.validations.isBusinessNameValid &&
      this.validations.isTradingNameValid &&
      this.validations.isTradingAddressValid &&
      this.validations.isPostalAddressValid &&
      this.validations.isAclValid
    );
  };

  handleBlur = (e: any) => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState({ [name]: value });

    this.inlineValidate(name, value);
    this.forceUpdate();
  };

  handleAddressBlur = (name: string, address: AddressStructure, isLookupSuccess: boolean) => {
    this.inlineValidate(
      name === "businessAddress" ? "tradingAddress" : name,
      isLookupSuccess ? address.label : "",
    );
    this.forceUpdate();
  };

  validateAbn = (abn: string) => {
    if (abn.length > 0) {
      if (Validator.validateAbn(abn)) {
        this.searchNzbn(abn);
      } else {
        this.setFalseAbn();
      }
    } else {
      this.validations.isAbnValid = true;
    }
  };

  searchNzbn = (abn: string) => {
    Validator.searchNzbn(abn).then(response => {
      if (!response.hasError && response.result && response.result.resultCount === 1) {
        const result = response.result.resultList[0];
        this.validations.isAbnValid = result.status === "Registered";
        this.validations.isAbnValidated = this.validations.isAbnValid;
        this.validations.isBusinessNameValid = result.companyName;
        this.setState({
          businessName: result.companyName,
          abn: result.businessNumber,
          acn: result.companyNumber,
        });
      } else {
        this.setFalseAbn();
      }
    });
  };

  setFalseAbn = () => {
    this.validations.isAbnValid = false;
    this.validations.isAbnValidated = false;
    this.setState({
      businessName: "",
    });
  };

  goBack = () => {
    this.props.goBack(this.state);
  };

  setBusinessAddress = (address: AddressStructure) => {
    this.setState({ tradingAddress: address });
    this.inlineValidate("tradingAddress", address.label);
    this.forceUpdate();
  };

  setPostalAddress = (address: AddressStructure) => {
    this.setState({ postalAddress: address });
    this.inlineValidate("postalAddress", address.label);
    this.forceUpdate();
  };

  render() {
    return (
      <fieldset className="business-page">
        <legend className="business-page__title">Your business details</legend>
        <div className="row">
          <FormGroup className="col-md-12">
            <Label htmlFor="abn">
              NZBN/NZCN {!this.props.isOverseasPartner && <span className="required">*</span>}
            </Label>
            <Input
              type="tel"
              id="displayAbn"
              invalid={!this.validations.isAbnValid}
              placeholder={`Enter your NZBN/NZCN`}
              value={this.state.displayAbn}
              onChange={this.handleUserInput}
              onBlur={this.handleBlur}
            />
            <FormFeedback style={this.validations.isAbnValid ? {} : { display: "block" }}>
              A valid NZBN is required
            </FormFeedback>
            <div className="abn-link">
              <a
                href="https://companies-register.companiesoffice.govt.nz/"
                target="_blank"
                rel="noreferrer"
              >
                Help locating your NZBN
              </a>
            </div>
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-md-6">
            <Label htmlFor="businessName">
              Business name <span className="required">*</span>
            </Label>
            <Input
              type="text"
              id="businessName"
              placeholder="Registered business name"
              value={this.state.businessName}
              onChange={this.handleUserInput}
              invalid={!this.validations.isBusinessNameValid}
              onBlur={this.handleBlur}
            />
            <FormFeedback>Business name is required</FormFeedback>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label htmlFor="tradingName">
              Trading name <span className="required">*</span>
            </Label>
            <Input
              required
              type="text"
              id="tradingName"
              placeholder="Trading name (if applicable)"
              value={this.state.tradingName}
              onChange={this.handleUserInput}
              invalid={!this.validations.isTradingNameValid}
              onBlur={this.handleBlur}
            />
            <FormFeedback>A trading name is required</FormFeedback>
          </FormGroup>
        </div>

        <FormGroup className="form-group remove-margin-bottom">
          <Label htmlFor="tradingAddress">
            Trading address {!this.props.isOverseasPartner && <span className="required">*</span>}
          </Label>

          <AddressFinder
            id="businessAddress"
            placeholder="Trading address"
            currentAddress={this.state.tradingAddress}
            onBlur={this.handleAddressBlur}
            isInvalid={!this.validations.isTradingAddressValid}
            onSelected={this.setBusinessAddress}
          />
        </FormGroup>

        <FormGroup className="form-check">
          <CustomInput
            className="form-check-input"
            type="checkbox"
            id="isPostalAddressDifferent"
            onChange={this.handleCheck}
            checked={this.state.isPostalAddressDifferent}
            label="I have a different postal address"
          />
        </FormGroup>
        {this.state.isPostalAddressDifferent ? (
          <FormGroup>
            <Label htmlFor="postalAddress">
              Postal address <span className="required">*</span>
            </Label>
            <AddressFinder
              id="postalAddress"
              placeholder="Postal address"
              currentAddress={this.state.postalAddress}
              onBlur={this.handleAddressBlur}
              isInvalid={!this.validations.isPostalAddressValid}
              onSelected={this.setPostalAddress}
            />
          </FormGroup>
        ) : (
          ""
        )}
        <FormGroup>
          <Label htmlFor="website">Website</Label>
          <Input
            type="text"
            id="website"
            placeholder="www.sitename.com"
            value={this.state.website}
            onChange={this.handleUserInput}
          />
        </FormGroup>
        <div>
          <FormGroup>
            <Label>
              GST Registered <span className="required">*</span>
            </Label>
            <ButtonGroup className="btn-group btn-group-toggle w-100" data-toggle="buttons">
              <Label className={Validator.getGstRadioClass(this.state.gstRegistered || false)}>
                <Input
                  type="radio"
                  name="gstOptions"
                  id="gstRegistered"
                  onChange={() => this.handleGst(true)}
                />
                Yes, I am
              </Label>
              <Label className={Validator.getGstRadioClass(!this.state.gstRegistered || false)}>
                <Input
                  type="radio"
                  name="gstOptions"
                  id="gstNotRegistered"
                  onChange={() => this.handleGst(false)}
                />
                No, I'm not
              </Label>
            </ButtonGroup>
          </FormGroup>
        </div>
        <div>
          <FormGroup>
            <Label htmlFor="acl">FSP Number</Label>
            <Input
              type="tel"
              id="acl"
              placeholder="FSP Number"
              value={this.state.acl}
              onChange={this.handleUserInput}
              disabled={this.state.hasAcl}
              invalid={!this.validations.isAclValid}
            />

            <FormFeedback>FSP must be 5 or 6 digits</FormFeedback>
            <FormGroup className="form-check">
              <CustomInput
                className="form-check-input"
                type="checkbox"
                id="hasAcl"
                onChange={this.handleCheck}
                checked={this.state.hasAcl}
                label="I don't have FSP Number"
              />
            </FormGroup>
          </FormGroup>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="footer-buttons" />
          </div>
        </div>

        <div className="row business-page__terms-and-conditions">
          <div className="col-md-6 col-lg-7">
            <FormGroup className="form-check">
              <CustomInput
                className="form-check-input"
                type="checkbox"
                id="provideConsent"
                invalid={!this.validations.consentGiven}
                onChange={this.handleCheck}
                label="I agree to accept Prospa's"
              />{" "}
              <a href="https://www.prospa.co.nz/privacy-policy" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
              <label>,</label>{" "}
              <a
                href="https://www.prospa.co.nz/consent-information#electronicauthorisation"
                target="_blank"
                rel="noreferrer"
              >
                Electronic Authorisation
              </a>{" "}
              <label> and </label>{" "}
              <a
                href="https://prospacontent.wpengine.com/wp-content/uploads/2024/04/Prospa-Partner-Terms-Updated-May-2024.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Partner Terms
              </a>
              <span className="required">*</span>
              <FormFeedback style={this.validations.consentGiven ? {} : { display: "block" }}>
                Please give your consent
              </FormFeedback>
            </FormGroup>
          </div>
          <div className="col-md-5">
            <FormGroup className="business-page__navigation-buttons">
              <Button
                className="prev-btn"
                color="secondary"
                onClick={this.goBack}
                data-testid="previous"
              >
                Previous
              </Button>
              <Button
                className="btn btn-primary"
                color="primary"
                onClick={this.handleValidations}
                data-testid="continue"
              >
                Continue
              </Button>
            </FormGroup>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default BusinessPage;
