import React from "react";

import "./StepContainer.scss";

import { appSteps } from "../../services/PageService";
import pencil from "@prospa/icons/dist/svg/WebNote.svg";
import tick from "@prospa/icons/dist/svg/Tick.svg";

export interface IStepProps {
  currentIndex: number;
  goPage: any;
}

const StepContainer = (props: IStepProps) => {
  const getStepState = (index: number) => {
    if (index === props.currentIndex) {
      return "active";
    }
    if (index > props.currentIndex) {
      return "pending";
    }
    return "completed";
  };

  const getClassName = (state: ReturnType<typeof getStepState>) => {
    if (state === "active") {
      return "pds-steps__item pds-steps__item--active";
    }
    if (state === "pending") {
      return "pds-steps__item pds-steps__item--pending";
    }
    return "pds-steps__item pds-steps__item--done";
  };
  return (
    <div>
      <ol className="pds-steps mx-auto">
        {appSteps.map(step => {
          const state = getStepState(step.index);
          const className = getClassName(state);
          return (
            <li
              key={`step-${step.index}`}
              className={className}
              onClick={props.goPage.bind(this, step.index)}
            >
              <div className="pds-steps__item__image">
                {state === "active" ? (
                  <img src={pencil} alt="icon of a pencil" />
                ) : state === "completed" ? (
                  <img src={tick} alt="icon of a tick" />
                ) : null}
              </div>
              <p>{step.name}</p>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default StepContainer;
