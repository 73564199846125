import { BankPageState } from '../components/BankPage/BankPage';
import { BusinessPageState } from '../components/BusinessPage/BusinessPage';
import { ProfilePageState } from '../components/ProfilePage/ProfilePage';
import { RegisterPageState } from '../components/RegisterPage/RegisterPage';
import { stripDash, stripSpace } from './FormValidation';
import { nzMobileReg } from './PhoneValidationFormat';

export interface leadGenUtmModel {
  UtmCampaign?: string;
  UtmContent?: string;
  UtmMedia?: string;
  UtmSource?: string;
  UtmTerm?: string;
  UtmKeyword?: string;
  MktoTrk?: string;
  WebsiteUrl?: string;
}

export interface leadGenApiModel {
  correlationId: string;
  leadType: string; // value=partner
  digitalLeadSource: string; // value=partner-registration
  googleTagManagerId: string; // get from adrian???
  firstName?: string;
  lastName?: string;
  fullName?: string;
  phoneNumber?: string;
  mobileNumber?: string;
  emailAddress?: string;
  partnerType?: string;
  isDigitalPartner?: boolean;
  otherPartnerType?: string; //not in api
  primaryAggregator?: string[];
  primaryAssociation?: string[];
  otherAggregator?: string;
  otherAssociation?: string;
  whatPromptedYouToRegister?: string;
  abn?: string;
  acn?: string;
  businessnumber?: string;
  companyNumber?: string;
  abnacn?: string; // why???
  tradingName?: string;
  registeredName?: string;
  businessStructure?: string; // ???
  businessStreet?: string;
  businessCity?: string;
  businessState?: string;
  businessPostalCode?: string;
  businessCountry?: string;
  mailingStreet?: string;
  mailingCity?: string;
  mailingState?: string;
  mailingPostalCode?: string;
  mailingCountry?: string;
  website?: string;
  gstRegistered?: boolean;
  australianCreditLicense?: string;
  fspNumber?: string;
  productName?: string;
  termsandconditions?: boolean;
  yearsOperating?: string;
  numberOfStaffMembers?: string;
  numberOfActiveCustomers?: string;
  targetMarkets?: string[];
  mainProducts?: string[];
  otherProducts?: string; // not in api
  businessMarketingMethods?: string[];
  otherBusinessMarketingMethods?: string;
  customerCommunicationMethods?: string[];
  otherCustomerCommunicationMethods?: string;
  bsb?: string;
  accountNumber?: string;
  accountName?: string;
  nameOfBank?: string;
  IsReadyForSubmission: boolean;
  countryCode: string;
}

export const getBaseLeadGenApiModel = (correlationId: string): leadGenApiModel => {
  return {
    correlationId: correlationId,
    leadType: "partner",
    digitalLeadSource: "partner-registration",
    googleTagManagerId: "",
    IsReadyForSubmission: false,
    countryCode: 'nz'
  };
};

export const getRegisterPageData = (data: RegisterPageState) => {
  const name = breakNames(data.fullName || "");
  return {
    fullName: data.fullName,
    firstName: name.firstname,
    lastName: name.lastname,
    emailAddress: data.emailAddress,
    businessState: data.businessState,
    partnerType: data.partnerType === "Digital Partner" ? "Broker" : data.partnerType,
    otherPartnerType: data.otherPartnerType,
    isDigitalPartner: data.partnerType === "Digital Partner",
    otherAggregator: data.noPrimeAggregator ? "" : data.otherAggregator,
    otherAssociation: data.noPrimeAssociation ? "" : data.otherAssociation,
    whatPromptedYouToRegister: data.registerReason,
    phoneNumber: data.phoneNumber,
    mobileNumber: data.phoneNumber,
  };
};

export const isNzMobile = (phoneNumber: string) => {
  return nzMobileReg.test(phoneNumber);
};

export const getBusinessPageData = (data: BusinessPageState) => {
  const result = {
    businessNumber: stripDash(stripSpace(data.abn)),
    companyNumber: data.acn,
    tradingName: data.tradingName,
    registeredName: data.businessName,
    businessStructure: data.businessStructure,
    website: data.website,
    gstRegistered: data.gstRegistered,
    fspNumber: data.hasAcl ? null : `FSP${data.acl}`,
    termsandconditions: data.provideConsent,
    productName: "NZ Small Business Loans(Unsecured)",
  };

  if (data.tradingAddress) {
    Object.assign(result, {
      businessStreet: data.tradingAddress.Street,
      businessCity: data.tradingAddress.City,
      businessPostalCode: data.tradingAddress.PostalCode,
      businessCountry: data.tradingAddress.Country,
    });
  }
  if (data.postalAddress) {
    Object.assign(result, {
      mailingStreet: data.postalAddress.Street,
      mailingCity: data.postalAddress.City,
      mailingPostalCode: data.postalAddress.PostalCode,
      mailingCountry: data.postalAddress.Country,
    });
  }
  return result;
};

export const getProfilePageData = (data: ProfilePageState) => {
  return {
    yearsOperating: data.operationYears,
    numberOfStaffMembers: data.staffNumber,
    numberOfActiveCustomers: data.activeCustomers,
    targetMarkets: getValuesFromObject(data.targetMarket),
    mainProducts: getValuesFromObject(data.mainProduct),
    otherProducts: data.otherProduct, // not in api
    businessMarketingMethods: getValuesFromObject(data.businessGeneration),
    otherBusinessMarketingMethods: data.otherBusiness,
    customerCommunicationMethods: getValuesFromObject(data.customerComms),
    otherCustomerCommunicationMethods: data.otherComms,
  };
};

export const getBankPageData = (data: BankPageState) => {
  return {
    bsb: data.bsb || null,
    accountNumber: data.accountNo || null,
    accountName: data.accountName || null,
    nameOfBank: data.bankName || null,
    IsReadyForSubmission: true,
  };
};

const getValuesFromObject = (data: any) => {
  return data.map(d => d.value);
};

const breakNames = (fullname: string) => {
  const names = fullname.split(" ");
  const lastName = names[names.length - 1];
  const firstnames = names.splice(0, names.length - 1);
  return { firstname: firstnames.join(" "), lastname: lastName };
};
