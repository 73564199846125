import React from "react";
import { Input, FormFeedback } from "reactstrap";
import type { SelectProps } from "./types";

import styles from "./Select.module.scss";

const Select = (props: SelectProps) => {
  return (
    <div className={styles["custom-select-component"]}>
      <Input type="select" {...props}>
        {props.children}
      </Input>
      {props.errorMessage ? <FormFeedback>{props.errorMessage}</FormFeedback> : null}
    </div>
  );
};

export default Select;
