import React from "react";
import "./App.scss";

import SideBanner from "./components/SideBanner";
import PageContainer from "./containers/PageContainer";
import ThanksPage from "./containers/ThanksPage";

export interface AppState {
  isThanksPage: boolean;
  fullName?: string;
  correlationId?: string;
}

class App extends React.Component<any, AppState> {
  constructor(props: any) {
    super(props);
    this.state = { isThanksPage: false, correlationId: "" };
  }

  pageChange = (fullName: string) => {
    this.setState({ isThanksPage: true, fullName: fullName });
  };

  setCorrelationId = (correlationId: string) => {
    this.setState({ correlationId: correlationId });
  };

  render() {
    return (
      <div className="app">
        <div className="app__side-banner">
          <SideBanner />
        </div>
        <div className="app__content">
          <div className="main">
            <div className="inner-container">
              {this.state.isThanksPage ? (
                <ThanksPage customer={this.state.fullName} />
              ) : (
                <PageContainer
                  handlePageChange={this.pageChange}
                  onGetCorrelationId={this.setCorrelationId}
                />
              )}
            </div>
          </div>
        </div>
        <input type="hidden" data-testid="correlation-id" value={this.state.correlationId} />
      </div>
    );
  }
}

export default App;
