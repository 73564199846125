import { leadGenUtmModel } from "./LeadService";

declare global {
  interface Window {
    __env: any;
    expressCapture: any;
    dataLayer: any;
    ga: any;
    AddressFinder: any;
  }
}

export const appSteps = [
  { index: 0, name: "Register" },
  { index: 1, name: "Business" },
  { index: 2, name: "Profile" },
  { index: 3, name: "Bank" }
];

export const getUtm = (): leadGenUtmModel => {
  const result: leadGenUtmModel = {};
  const params = new URLSearchParams(document.location.search.substring(1));
  Object.assign(result, {
    UtmCampaign: params.get("utm_campaign"),
    UtmSource: params.get("utm_source"),
    UtmMedium: params.get("utm_medium"),
    UtmContent: params.get("utm_content"),
    UtmTerm: params.get("utm_term"),
    UtmKeyWord: params.get("utm_keyword"),
    MktoTrk: "id:317-LRS-411&token:_mch-prospa.com-1541571734475-23006",
    WebsiteUrl: document.location.hostname
  });
  return result;
};

export const fireGtmEvent = (page: string) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "dataLayerPageview",
      pagePath: `${page.toLocaleLowerCase()}`
    });
  }
};

//we don't know if we will be needing this...
export const fireGaEvent = (page: string) => {
  if (window.ga) {
    // window.ga("send", "pageview", page);
    console.log("send", "pageview", page);
  }
};
