import * as React from "react";

import "./SideBanner.scss";

import prospaWhite from "../../assets/prospa-white.svg";

export const SideBanner: React.FunctionComponent = () => {
  return (
    <div className="side-banner">
      <div className="side-banner__logo mx-auto">
        <img src={prospaWhite} alt="Prospa logo" width="184px" />
      </div>
    </div>
  );
};
