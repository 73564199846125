import React from "react";

import "./Thankspage.scss";
import PageFooter from "../PageFooter";
import plane from "@prospa/icons/dist/svg/MicroilloPaperplaneCard1.svg";
import { fireGtmEvent } from "../../services/PageService";

export interface ThanksPageProps {
  customer?: string;
}

export interface ThanksPageState {}

class ThanksPage extends React.Component<ThanksPageProps, ThanksPageState> {
  componentDidMount = () => {
    fireGtmEvent("success");
  };

  render() {
    return (
      <div className="thanks-page" data-testid="thanksContainer">
        <img src={plane} className="thanks-page-image mx-auto" alt="icon of a paper plane" />
        <div className="thanks-page__message">
          <h3>Thank you {this.props.customer}!</h3>
          <p>
            Thanks for providing your details. We'll now start processing them and one of our team
            members will be in touch with you ASAP.
          </p>
        </div>
        <div>
          <div className="thanks-page__deal">
            <h5>Got a deal scenario?</h5>
            <p>
              Give us a call on <a href="tel:0800005797">0800 005 797</a> or email us at
              <br />
              <a href="mailto:partners@prospa.co.nz">partners@prospa.co.nz</a>
            </p>
          </div>
          <PageFooter />
        </div>
      </div>
    );
  }
}

export default ThanksPage;
