import tokens from "@prospa/tokens";
import hexAlpha from "hex-alpha";

const baseStyles = {
  indicatorSeparator: (styles: any) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    opacity: 0.6,
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    "&:active": { opacity: 1 },
    "&:hover": { opacity: 1 },
    opacity: 0.4,
    marginRight: "-10px",
  }),
  placeholder: (styles: any) => ({
    ...styles,
    fontWeight: "400",
    fontSize: "16px",
  }),
  control: (styles: any) => ({
    ...styles,
    cursor: "pointer",
    boxShadow: "none",
    fontSize: "16px",
    minHeight: "56px",
    fontWeight: "500",
  }),
  multiValueLabel: (styles: any) => ({
    ...styles,
    fontSize: "16px",
    color: tokens.pds.color.neutral[900],
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: tokens.pds.color.neutral[100],
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    opacity: 0.6,
    backgroundColor: tokens.pds.color.neutral[200],
    "&:hover": {
      backgroundColor: tokens.pds.color.neutral[200],
      opacity: 1,
    },
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    cursor: "pointer",
    color: tokens.pds.color.neutral[600],
    backgroundColor: state.isSelected || state.isFocused ? tokens.pds.color.neutral[50] : "",
    "&:hover": { backgroundColor: tokens.pds.color.neutral[50] },
  }),
};

export const errorStyle = {
  ...baseStyles,
  control: (styles: any) => ({
    ...baseStyles.control(styles),
    border: `2px solid ${tokens.pds.color.red[500]}`,
    backgroundColor: `${hexAlpha(tokens.pds.color.red[50], 0.7)}`,
    color: tokens.pds.color.neutral[600],
    "&:hover": { borderColor: tokens.pds.color.red[500] },
    "&:focus": { borderColor: tokens.pds.color.red[500] },
  }),
  placeholder: (styles: any) => ({
    ...baseStyles.placeholder(styles),
    color: tokens.pds.color.red[500],
  }),
};

export const selectStyle = {
  ...baseStyles,
  control: (styles: any) => ({
    ...baseStyles.control(styles),
    "&:hover": { borderColor: tokens.pds.color.neutral[900] },
    "&:focus": { borderColor: tokens.pds.color.neutral[900] },
    color: tokens.pds.color.neutral[600],
    border: `2px solid ${tokens.pds.color.neutral[200]}`,
  }),
  placeholder: (styles: any) => ({
    ...baseStyles.placeholder(styles),
    color: tokens.pds.color.neutral[600],
  }),
};
