import React from "react";

import "./PageFooter.scss";

import story from "@prospa/icons/dist/svg/WebTickBubble.svg";
import link from "@prospa/icons/dist/svg/UtilitiesShare.svg";

const PageFooter = () => {
  return (
    <div className="row">
      <div className="col-md-6 text-center">
        <img className="mb-2" src={story} alt="Speech bubble with a checkmark in it." />
        <p className="footer-title">Success story</p>
        <p className="footer-subtitle">See how working with Prospa helped one of our partners</p>
        <p>
          <a
            className="footer-link"
            href="https://www.prospa.com/small-business-loan/success-stories"
          >
            Read success story
          </a>
        </p>
      </div>
      <div className="col-md-6 text-center">
        <img className="mb-2" src={link} alt="Three connected circles, to denote linking up." />
        <p className="footer-title">Join our network</p>
        <p className="footer-subtitle">
          Connect with us on LinkedIn for the latest news and insights
        </p>
        <p>
          <a className="footer-link" href="https://www.linkedin.com/company/prospa/">
            Prospa LinkedIn
          </a>
        </p>
      </div>
    </div>
  );
};

export default PageFooter;
