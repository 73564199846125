import React from "react";

import { getCorrelationId, sendLead } from "../../services/LeadApiService";

import StepContainer from "../StepContainer";

import RegisterPage from "../../components/RegisterPage";
import FooterContainer from "../FooterContainer";
import BusinessPage from "../../components/BusinessPage";
import ProfilePage from "../../components/ProfilePage";
import BankPage from "../../components/BankPage";

import { RegisterPageState } from "../../components/RegisterPage/RegisterPage";
import { BusinessPageState } from "../../components/BusinessPage/BusinessPage";
import { ProfilePageState } from "../../components/ProfilePage/ProfilePage";
import { BankPageState } from "../../components/BankPage/BankPage";
import {
  leadGenApiModel,
  getRegisterPageData,
  getBusinessPageData,
  getProfilePageData,
  getBankPageData
} from "../../services/LeadService";

export interface PageProps {
  handlePageChange: any;
  onGetCorrelationId: any;
}
export interface PageState {
  currentPage: number;
  registerPageData?: RegisterPageState;
  businessPageData?: BusinessPageState;
  profilePageData?: ProfilePageState;
  bankPageData?: BankPageState;
  registrationData?: leadGenApiModel;
}

class PageContainer extends React.Component<PageProps, PageState> {
  constructor(props: PageProps) {
    super(props);

    this.state = { currentPage: 0 };

    getCorrelationId().then((result: leadGenApiModel) => {
      this.props.onGetCorrelationId(result.correlationId);
      this.setState({ registrationData: result });
    });
  }

  handleContinue = (pageState: any) => {
    const data = this.updatePageData(pageState);
    this.setState({ currentPage: this.state.currentPage + 1 });
    sendLead(data);
  };

  goPage = (page: number) => {
    if (page < this.state.currentPage) {
      this.setState({ currentPage: page });
    }
  };

  goBack = (pageState: any) => {
    this.updatePageData(pageState);
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  updatePageData = (pageState: any): leadGenApiModel => {
    const data = this.state.registrationData;
    switch (this.state.currentPage) {
      case 0:
        this.setState({ registerPageData: pageState });
        Object.assign(data, getRegisterPageData(pageState));
        break;
      case 1:
        this.setState({ businessPageData: pageState });
        Object.assign(data, getBusinessPageData(pageState));
        break;
      case 2:
        this.setState({ profilePageData: pageState });
        Object.assign(data, getProfilePageData(pageState));
        break;
      case 3:
        this.setState({ bankPageData: pageState });
        Object.assign(data, getBankPageData(pageState));
        break;
    }
    this.setState({ registrationData: data });
    return data;
  };

  getPage = (page: number) => {
    switch (page) {
      case 0:
        return (
          <RegisterPage
            handleClick={this.handleContinue}
            pageState={this.state.registerPageData}
          />
        );
      case 1:
        return (
          <BusinessPage
            handleClick={this.handleContinue}
            pageState={this.state.businessPageData}
            goBack={this.goBack}
            isOverseasPartner={this.state.registerPageData.isOverseasPartner}
          />
        );
      case 2:
        return (
          <ProfilePage
            handleClick={this.handleContinue}
            pageState={this.state.profilePageData}
            goBack={this.goBack}
          />
        );
      case 3:
        return (
          <BankPage
            handleClick={this.handleContinue}
            pageState={this.state.bankPageData}
            goBack={this.goBack}
            isOverseasPartner={this.state.registerPageData.isOverseasPartner}
            isAggregatorOther={
              (
                this.state.registerPageData.otherAggregator || ""
              ).toLowerCase() === "other"
            }
          />
        );
      case 4:
      default:
        const name = this.state.registerPageData
          ? this.state.registerPageData.fullName.split(" ")
          : "";
        this.props.handlePageChange(name[0]);
        break;
    }
  };

  render() {
    return (
      <div>
        <StepContainer
          currentIndex={this.state.currentPage}
          goPage={this.goPage}
        />
        <div className="container">
          <form className="px-3 mb-3">
            {this.getPage(this.state.currentPage)}
          </form>
          <FooterContainer />
        </div>
      </div>
    );
  }
}

export default PageContainer;
