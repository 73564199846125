import httpClientInstance from './HttpClient';

export const sendRequest = (
  url: string,
  method: string = "GET",
  body?: any,
  token?: string
) => {
  const options = {
    uri: url,
    method: method,
    body: body || {},
    json: true,
    headers: {}
  };


  const response = httpClientInstance.get<any>(
    url,
    { data: options.body },
  );


  return response
    .then(response => {
      return { hasError: false, result: response.data };
    })
    .catch(err => {
      return { hasError: true, result: err };
    });
};
