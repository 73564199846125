import React from "react";
import { Button, CustomInput, FormGroup, Label, Input, FormFeedback } from "reactstrap";

import * as Validator from "../../services/FormValidation";
import { fireGtmEvent } from "../../services/PageService";
import Select from "../Select";
import "./RegisterPage.scss";

export interface RegisterPageProps {
  handleClick: any;
  pageState?: RegisterPageState;
}

export interface RegisterPageState {
  fullName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  businessState?: string;
  partnerType?: string;
  otherPartnerType?: string;
  isDigitalPartner?: boolean;
  otherAggregator?: string;
  otherAssociation?: string;
  registerReason?: string;
  noPrimeAggregator?: boolean;
  noPrimeAssociation?: boolean;
  isOverseasPartner?: boolean;
}

interface pageValidations {
  isEmailValid?: boolean;
  isNameValid?: boolean;
  isPhoneValid?: boolean;
  isStateValid?: boolean;
  isEmailTouched?: boolean;
  isNameTouched?: boolean;
  isPhoneTouched?: boolean;
  isStateTouched?: boolean;
  isPartnerTypeProvided?: boolean;
  isAggregatorProvided?: boolean;
  isAssociationProvided?: boolean;
  isReasonProvided?: boolean;
}

class RegisterPage extends React.Component<RegisterPageProps, RegisterPageState> {
  constructor(props: RegisterPageProps) {
    super(props);

    this.state = this.props.pageState || {
      fullName: "",
      phoneNumber: "",
      emailAddress: "",
      businessState: "",
      partnerType: "",
      otherPartnerType: "",
      otherAggregator: "",
      otherAssociation: "",
      registerReason: "",
      noPrimeAggregator: false,
      noPrimeAssociation: false,
      isOverseasPartner: false,
    };
  }

  validations: pageValidations = {
    isEmailValid: true,
    isNameValid: true,
    isPhoneValid: true,
    isStateValid: true,
    isPartnerTypeProvided: true,
    isAggregatorProvided: true,
    isAssociationProvided: true,
    isReasonProvided: true,

    isEmailTouched: false,
    isNameTouched: false,
    isPhoneTouched: false,
    isStateTouched: false,
  };

  componentDidMount = () => {
    fireGtmEvent("register");
  };

  handleUserInput = (e: any) => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState({ [name]: value });
    this.inlineValidate(name, value);
  };

  handleBlur = (e: any) => {
    const name = e.target.id;
    const value = e.target.value;
    this.inlineValidate(name, value, true);
  };

  handleCheck = (e: any) => {
    const name = e.target.id;
    const value = e.target.checked;
    this.setState({ [name]: value });
    this.updateValidations(name, value);
  };

  handleIsOverseasPartnerCheck = (e: any) => {
    const { checked } = e.target;
    this.setState({ isOverseasPartner: checked, businessState: "" });
  };

  updateValidations = (name: string, value: boolean) => {
    switch (name) {
      case "hasAggregator":
        this.validations.isAggregatorProvided =
          Validator.isPresent(this.state.otherAggregator) || value;
        break;
      case "hasAssociation":
        this.validations.isAssociationProvided =
          Validator.isPresent(this.state.otherAssociation) || value;
        break;
    }
  };

  inlineValidate = (name: string, value: string, touched?: boolean) => {
    const {
      partnerType,
      otherPartnerType,
      isOverseasPartner,
      noPrimeAggregator,
      noPrimeAssociation,
    } = this.state;

    switch (name) {
      case "fullName":
        this.validations.isNameValid = Validator.validateFullName(value);
        this.validations.isNameTouched = touched || this.validations.isNameTouched;
        break;

      case "emailAddress":
        this.validations.isEmailValid = Validator.validateEmail(value);
        this.validations.isEmailTouched = touched || this.validations.isEmailTouched;
        break;

      case "phoneNumber":
        this.validations.isPhoneValid = Validator.validatePhone(value);
        this.validations.isPhoneTouched = touched || this.validations.isPhoneTouched;
        break;

      case "businessState":
        this.validations.isStateValid = true;
        this.validations.isStateTouched = touched || this.validations.isStateTouched;
        break;

      case "partnerType":
        this.validations.isPartnerTypeProvided = Validator.isPresent(value);
        if (this.validations.isPartnerTypeProvided && value === "Other") {
          this.validations.isPartnerTypeProvided = Validator.isPresent(otherPartnerType);
        }
        break;

      case "otherPartnerType":
        this.validations.isPartnerTypeProvided = Validator.isPresent(partnerType);
        if (this.validations.isPartnerTypeProvided && partnerType === "Other") {
          this.validations.isPartnerTypeProvided = Validator.isPresent(value);
        }
        break;

      case "otherAggregator":
        this.validations.isAggregatorProvided =
          isOverseasPartner ||
          partnerType === "Digital Partner" ||
          noPrimeAggregator ||
          Validator.isPresent(value);
        break;

      case "otherAssociation":
        this.validations.isAssociationProvided =
          isOverseasPartner ||
          partnerType === "Digital Partner" ||
          noPrimeAssociation ||
          Validator.isPresent(value);
        break;

      case "registerReason":
        this.validations.isReasonProvided = Validator.isPresent(value);
        break;
    }
    this.forceUpdate();
  };

  handleValidations = (e: any) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.handleClick(this.state);
    } else {
      this.forceUpdate();
    }
  };

  handleNoPrimeAggregatorCheck = (e: any) => {
    const { checked } = e.target;
    this.setState({ noPrimeAggregator: checked });
    this.updateValidations("noPrimeAggregator", checked);
  };

  handleNoPrimeAssociationCheck = (e: any) => {
    const { checked } = e.target;
    this.setState({ noPrimeAssociation: checked });
  };

  disableAggregatorInput = () => {
    const { isOverseasPartner, partnerType } = this.state;
    return !partnerType || partnerType === "Digital Partner" || isOverseasPartner;
  };

  validate = () => {
    this.inlineValidate("fullName", this.state.fullName, true);
    this.inlineValidate("emailAddress", this.state.emailAddress, true);
    this.inlineValidate("phoneNumber", this.state.phoneNumber, true);
    this.inlineValidate("businessState", this.state.businessState, true);
    this.inlineValidate("partnerType", this.state.partnerType, true);
    this.inlineValidate("otherPartnerType", this.state.otherPartnerType, true);
    this.inlineValidate("registerReason", this.state.registerReason, true);

    this.inlineValidate("otherAggregator", this.state.otherAggregator, true);
    this.inlineValidate("otherAssociation", this.state.otherAssociation, true);

    return (
      this.validations.isEmailValid &&
      this.validations.isNameValid &&
      this.validations.isPhoneValid &&
      this.validations.isStateValid &&
      this.validations.isPartnerTypeProvided &&
      this.validations.isAggregatorProvided &&
      this.validations.isAssociationProvided &&
      this.validations.isReasonProvided
    );
  };

  render() {
    return (
      <fieldset className="register-page">
        <legend className="register-page__title">Partner registration</legend>
        <div className="row">
          <FormGroup className="col-md-6">
            <Label for="fullName">
              Full name <span className="required">*</span>
            </Label>
            <Input
              type="text"
              id="fullName"
              invalid={this.validations.isNameTouched && !this.validations.isNameValid}
              placeholder="Full name"
              value={this.state.fullName}
              onChange={this.handleUserInput}
              onBlur={this.handleBlur}
            />
            <FormFeedback>Full name is required</FormFeedback>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="phoneNumber">
              Phone number <span className="required">*</span>
            </Label>
            <Input
              type="tel"
              id="phoneNumber"
              invalid={this.validations.isPhoneTouched && !this.validations.isPhoneValid}
              placeholder="021 345 678"
              value={this.state.phoneNumber}
              onChange={this.handleUserInput}
              onBlur={this.handleBlur}
            />
            <FormFeedback>
              {this.state.phoneNumber === ""
                ? "Phone number is required"
                : "Please enter a valid phone number"}
            </FormFeedback>
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup className="col-md-6">
            <Label for="emailAddress">
              Email address <span className="required">*</span>
            </Label>
            <Input
              type="email"
              id="emailAddress"
              invalid={this.validations.isEmailTouched && !this.validations.isEmailValid}
              placeholder="john@mail.com"
              value={this.state.emailAddress}
              onChange={this.handleUserInput}
              onBlur={this.handleBlur}
            />
            <FormFeedback>
              {this.state.emailAddress === ""
                ? "Email address is required"
                : "Please enter a valid email address"}
            </FormFeedback>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="businessState">State</Label>
            <Select
              id="businessState"
              invalid={this.validations.isStateTouched && !this.validations.isStateValid}
              className={Validator.getSelectClass(this.state.businessState)}
              value={this.state.businessState}
              onChange={this.handleUserInput}
              onBlur={this.handleBlur}
              disabled
              errorMessage="Please select a state"
            >
              <option value="" disabled>
                Please select
              </option>
              <option value="NSW">New South Wales</option>
              <option value="QLD">Queensland</option>
              <option value="VIC">Victoria</option>
              <option value="SA">South Australia</option>
              <option value="NT">Northern Territory</option>
              <option value="WA">Western Australia</option>
              <option value="ACT">Australian Capital Territory</option>
              <option value="TAS">Tasmania</option>
            </Select>
            <FormGroup className="form-check remove-margin-bottom">
              <CustomInput
                className="form-check-input"
                type="checkbox"
                id="isOverseasPartner"
                onChange={this.handleIsOverseasPartnerCheck}
                checked={this.state.isOverseasPartner}
                label="I'm based outside of Australia/New Zealand"
              />
            </FormGroup>
          </FormGroup>
        </div>

        <div className="row">
          <FormGroup className="col-md-6">
            <Label for="partnerType">
              Partner type <span className="required">*</span>
            </Label>
            <Select
              id="partnerType"
              className={Validator.getSelectClass(this.state.partnerType)}
              value={this.state.partnerType}
              onChange={this.handleUserInput}
              invalid={!this.validations.isPartnerTypeProvided}
              errorMessage="Please provide a partner type"
            >
              <option value="" disabled>
                Please select
              </option>
              <option value="Broker">Broker</option>
              <option value="Accountant">Accountant</option>
              <option value="Bookkeeper">Bookkeeper</option>
              <option value="Financial Planner">Financial Planner</option>
              <option value="Digital Partner">Digital partner or affiliate</option>
              <option value="Other">Other</option>
            </Select>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="otherPartnerType">Other partner type</Label>
            <Input
              type="text"
              id="otherPartnerType"
              className="form-control"
              placeholder="If other, please specify"
              value={this.state.otherPartnerType}
              onChange={this.handleUserInput}
              disabled={this.state.partnerType !== "Other"}
              invalid={!this.validations.isPartnerTypeProvided}
            />
          </FormGroup>
        </div>

        <div className="row">
          <FormGroup className="col-md-6">
            <Label for="otherAggregator">
              Aggregator <span className="required">*</span>
            </Label>
            <Input
              type="text"
              id="otherAggregator"
              value={this.state.otherAggregator}
              onChange={this.handleUserInput}
              placeholder="Aggregator name"
              invalid={!this.validations.isAggregatorProvided}
              disabled={this.disableAggregatorInput() || this.state.noPrimeAggregator}
            />
            <FormFeedback>Please enter aggregator name</FormFeedback>
            <FormGroup className="form-check">
              <CustomInput
                className="form-check-input"
                type="checkbox"
                id="hasAggregator"
                onChange={this.handleNoPrimeAggregatorCheck}
                checked={!!this.state.noPrimeAggregator}
                disabled={this.disableAggregatorInput() || this.state.noPrimeAggregator}
                label="I don't have an aggregator"
              />
            </FormGroup>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="otherAssociation">
              Association <span className="required">*</span>
            </Label>
            <Input
              type="text"
              id="otherAssociation"
              value={this.state.otherAssociation}
              onChange={this.handleUserInput}
              placeholder="Association name"
              invalid={!this.validations.isAssociationProvided}
              disabled={this.disableAggregatorInput() || this.state.noPrimeAssociation}
            />
            <FormFeedback>Please enter association name</FormFeedback>
            <FormGroup className="form-check">
              <CustomInput
                className="form-check-input"
                type="checkbox"
                id="hasAssociation"
                onChange={this.handleNoPrimeAssociationCheck}
                checked={!!this.state.noPrimeAssociation}
                label="I don't have an association"
                disabled={this.disableAggregatorInput() || this.state.noPrimeAssociation}
              />
            </FormGroup>
          </FormGroup>
        </div>

        <FormGroup>
          <Label for="registerReason">
            Reason you are registering <span className="required">*</span>
          </Label>
          <Select
            id="registerReason"
            className={Validator.getSelectClass(this.state.registerReason) + " large-select"}
            value={this.state.registerReason}
            onChange={this.handleUserInput}
            invalid={!this.validations.isReasonProvided}
            errorMessage="Please select a reason"
          >
            <option value="" disabled>
              Please select
            </option>
            <option value="Aggregator referral/event">Aggregator referral/event</option>
            <option value="Association referral/event">Association referral/event</option>
            <option value="Prospa rep contacted me">Prospa rep contacted me</option>
            <option value="Email/letter from Prospa marketing">
              Email/letter from Prospa marketing
            </option>
            <option value="Advertising/media release">Advertising/media release</option>
            <option value="Online search">Online search</option>
            <option value="'How To Prospa' training">'How To Prospa' training</option>
            <option value="Client enquiry">Client enquiry</option>
          </Select>
        </FormGroup>
        <div className="row">
          <div className="col-md-12">
            <FormGroup className="footer-buttons">
              <Button
                className="btn btn-primary float-right"
                color="primary"
                onClick={this.handleValidations}
                data-testid="continue"
              >
                Continue
              </Button>
            </FormGroup>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default RegisterPage;
