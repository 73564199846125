import { sendRequest } from "./ApiService";
import { PhoneFormat, getFormattingMap } from "./PhoneValidationFormat";

export const getGstRadioClass = (isSelected: boolean) => {
  return isSelected ? "btn btn-group-toggle-selected" : "btn";
};

export const getSelectClass = (value?: string) => {
  return (value || "") === "" ? "form-select" : "form-select-selected";
};

export const getCheckClass = (isSet: boolean) => {
  return isSet
    ? "custom-control-input form-check-input"
    : "custom-control-input is-invalid form-check-input";
};

export const validateEmail = (email?: string) => {
  if (!email) return false;
  const regex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
  return regex.test(email);
};

export const validateFullName = (fullName?: string) => {
  if (!fullName) return false;
  const regex = /^[a-zA-Z']{2,}(?: [a-zA-Z']+){1,5}$/;
  return regex.test(fullName);
};

export const validatePhone = (phone?: string) => {
  if (!phone) return false;
  const validatedNumber = formatPhonenumber(phone);
  return validatedNumber.valid;
};

export const validateAbn = (abn?: string) => {
  if (!abn) return false;
  const len = stripDash(stripSpace(abn)).length;
  return len === 11 || len === 9 || len === 13 || len === 7;
};

export const isPresent = (value?: string) => {
  if (!value) return false;
  return value.length > 0;
};

export const isNumberPresent = (value?: string) => {
  if (isPresent(value)) {
    const regex = /^[0-9]*$/;
    return regex.test(value);
  }
  return false;
};

export const isOfLength = (value?: string, minLength: number = 0, maxLength: number = -1) => {
  if (isPresent(value)) {
    const regex = /^[0-9]*$/;
    const len = value.length;
    const result = regex.test(value);
    if (maxLength === -1) {
      return result && len >= minLength;
    } else {
      return result && len >= minLength && len <= maxLength;
    }
  }
  return false;
};

export const isArrayPopulated = (value?: []) => {
  if (!value || value.length === 0) return false;
  return true;
};

export const validateBsb = (bsb?: string) => {
  if (!bsb || isNaN(parseInt(bsb))) return false;
  return stripDash(stripSpace(bsb)).length === 6;
};

export const searchNzbn = (nzbn: string) => {
  return sendRequest(
    `${window.__env.nzbnLookupBaseUrl}/${stripDash(stripSpace(nzbn))}`,
    "GET",
    null,
    "7dcbfa6faae5147c09d93f26c8971ec9",
  );
};

export const stripSpace = (value?: string) => {
  if (!value) return "";
  return value.replace(/\s/g, "");
};

export const stripDash = (value?: string) => {
  if (!value) return "";
  return value.replace(/-/g, "");
};

//this piece of code is copy paste from E2E
const formatPhonenumber = (phoneNumber: string) => {
  if (!phoneNumber) return { valid: false, displayValue: phoneNumber, value: "" };
  phoneNumber = phoneNumber.replace(/[^0-9+]/g, "");

  // detect what type of number it is so that we can trim accordingly
  let type: PhoneFormat = null;

  const formattingMap = getFormattingMap();
  formattingMap.forEach(element => {});

  for (let index = 0; index < formattingMap.length; index++) {
    const item = formattingMap[index];

    const headRegex = new RegExp(item.regex.head, "g");
    if (phoneNumber.search(headRegex) !== -1) {
      type = item;
      break;
    }
  }

  let phoneValid = false;

  let displayValue = phoneNumber;
  if (type) {
    const regex = new RegExp(`${type.regex.head}${type.regex.body}`, "g");
    const matches = regex.exec(phoneNumber);

    if (matches) {
      displayValue = type.format(phoneNumber);
      if (phoneNumber.length >= type.minLength && phoneNumber.length <= type.maxLength) {
        phoneValid = true;
      }
    }
  }

  return { valid: phoneValid, displayValue: displayValue, value: phoneNumber };
};
