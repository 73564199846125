import React, { Component } from "react";
import Script from "react-load-script";
import { Input, FormFeedback } from "reactstrap";
import { Country, ContainerPrefix, AddressFinderWidgetSrc } from "./constants";
import { AddressMeta } from "./types";
import { AddressStructure } from "../BusinessPage/BusinessPage";
import "./AddressFinder.scss";


export interface AddressFinderProps {
  id: string;
  addressFinderKey?: string;
  country?: Country;
  container?: HTMLElement;
  inputClassName?: string;
  listClassName?: string;
  itemClassName?: string;
  hoverClassName?: string;
  placeholder: string;
  currentAddress: AddressStructure;
  isInvalid: boolean;
  onBlur: Function;
  onSelected: (address: AddressStructure) => void;
}

export interface AddressFinderState {
  currentAddress: AddressStructure;
}

class AddressFinder extends Component<AddressFinderProps, AddressFinderState> {
  public static defaultProps = {
    inputClassName: "form-control",
    listClassName: "address-autocomplete__suggestions",
    itemClassName: "address-autocomplete__suggestions__item",
    hoverClassName: "address-autocomplete__suggestions__item--active",
    country: Country.NZ,
    addressFinderKey: window.__env.AddressFinderKey,
  };

  subscription: any;
  lookupSuccess: boolean = false;
  name: string = "";
  mapping: string = "";
  addressType: string = "";

  constructor(props: AddressFinderProps) {
    super(props);
    this.state = {
      currentAddress: this.props.currentAddress
    };
  }

  scriptError = (error: any) => {
    this.lookupSuccess = false;
  };

  setupAddressFinder = () => {
    const AddressFinder = window.AddressFinder;

    const AddressFinderwidget = new AddressFinder.Widget(
      document.getElementById(this.props.id),
      this.props.addressFinderKey,
      this.props.country,
      {
        list_class: this.props.listClassName,
        item_class: this.props.itemClassName,
        hover_class: this.props.hoverClassName,
        manual_style: true,
        container:
          this.props.container ||
          document.getElementById(`${ContainerPrefix}-${this.props.id}`),
        address_params: {},
        max_results: 5
      }
    );

    AddressFinderwidget.on(
      "address:select",
      (fullAddress: string, metaData: AddressMeta) => {
        if (metaData.success) {
          const addressComponents = fullAddress.split(', ');
          const componentCount = addressComponents.length;
          const streetAddress = addressComponents.slice(0, componentCount - 1).join(', ');

          const selectedAddress: AddressStructure = {
            Street: streetAddress,
            City: metaData.selected_city.toUpperCase(),
            PostalCode: metaData.postcode,
            Country: 'New Zealand',
            label: fullAddress
          };

          this.lookupSuccess = true;
          this.setState({ currentAddress: selectedAddress });

          this.props.onSelected(selectedAddress);
        }
      }
    );
  };

  handleUserInput = (e: any) => {
    this.setState({
      currentAddress: {
        ...this.state.currentAddress,
        label: e.target.value
      }
    });
  };

  handleBlur = () => {
    this.props.onBlur(this.props.id, this.state.currentAddress, this.lookupSuccess);
  };

  render() {
    return (
      <address>
        <Script
          url={AddressFinderWidgetSrc}
          onError={this.scriptError}
          onLoad={this.setupAddressFinder}
        />
        <Input
          aria-autocomplete="list"
          aria-haspopup={true}
          aria-owns={
            this.props.container
              ? this.props.container.id
              : `${ContainerPrefix}-${this.props.id}`
          }
          autoComplete="off"
          className={this.props.inputClassName}
          type="text"
          id={this.props.id}
          name={this.props.id}
          placeholder={this.props.placeholder}
          value={this.state.currentAddress.label}
          onChange={this.handleUserInput}
          invalid={this.props.isInvalid}
          onBlur={this.handleBlur}
        />
        {!this.props.container && (
          <div
            id={`${ContainerPrefix}-${this.props.id}`}
            className="address-autocomplete"
          ></div>
        )}
        <FormFeedback>{this.props.placeholder} is required</FormFeedback>
      </address>
    );
  }
}

export default AddressFinder;
