import httpClientInstance from './HttpClient';
import { getBaseLeadGenApiModel, leadGenApiModel } from "./LeadService";
import { getUtm } from "./PageService";

export const getCorrelationId = () => {


  const request = httpClientInstance.post<any>(
    `${window.__env.leadGenerationApiBaseUrl}/api/partnerlead/v1`,
    { data:  getUtm() },
  );


  return request.then(response => {
    if (response) {
      return getBaseLeadGenApiModel(response.data);      
    } else {
      return getBaseLeadGenApiModel("error");
    }
  });
};

export const sendLead = (registrationData: leadGenApiModel) => {

  httpClientInstance.put<any>(
    `${window.__env.leadGenerationApiBaseUrl}/api/partnerlead`,
    { data:  registrationData });
};
