import React from "react";
import { Button, FormFeedback, FormGroup, Input, Label, CustomInput } from "reactstrap";

import * as Validator from "../../services/FormValidation";
import { fireGtmEvent } from "../../services/PageService";
import "./BankPage.scss";

export interface BankPageProps {
  handleClick: Function;
  goBack: Function;
  pageState?: BankPageState;
  isOverseasPartner: boolean;
  isAggregatorOther: boolean;
}

export interface BankPageState {
  bsb?: string;
  accountNo?: string;
  accountName?: string;
  bankName?: string;
  confirmedBankDetails?: boolean;
}

interface pageValidations {
  isBsbValid: boolean;
  isAccountValid: boolean;
  isAccountNameValid: boolean;
  isBankNameValid: boolean;
  isBankingVerified: boolean;
}

class BankPage extends React.Component<BankPageProps, BankPageState> {
  constructor(props: BankPageProps) {
    super(props);

    const pageState = this.props.pageState || {
      bsb: "",
      accountNo: "",
      accountName: "",
      bankName: "",
      confirmedBankDetails: false,
    };

    Object.assign(pageState, { confirmedBankDetails: false });

    this.state = pageState;
  }

  validations: pageValidations = {
    isBsbValid: true,
    isAccountValid: true,
    isAccountNameValid: true,
    isBankNameValid: true,
    isBankingVerified: true,
  };

  componentDidMount = () => {
    fireGtmEvent("bank");
  };

  handleUserInput = (e: any) => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState({ [name]: value });
    this.inlineValidate(name, value);
  };

  handleBlur = (e: any) => {
    const name = e.target.id;
    const value = e.target.value;
    this.inlineValidate(name, value);
  };

  inlineValidate = (name: string, value: string) => {
    const { isOverseasPartner } = this.props;
    switch (name) {
      case "bsb":
        this.validations.isBsbValid =
          value.length === 0 || (isOverseasPartner && !value) || Validator.validateBsb(value);
        break;
      case "accountNo":
        this.validations.isAccountValid =
          value.length === 0 || (isOverseasPartner && !value) || Validator.isOfLength(value, 5, 18);
        break;
      case "accountName":
        this.validations.isAccountNameValid =
          value.length === 0 || isOverseasPartner || Validator.isPresent(value);
        break;
      case "bankName":
        this.validations.isBankNameValid =
          value.length === 0 || isOverseasPartner || Validator.isPresent(value);
        break;
    }
    this.forceUpdate();
  };

  handleCheck = (e: any) => {
    const name = e.target.id;
    const value = e.target.checked;
    this.setState({ [name]: value });
    if (name === "confirmedBankDetails") {
      this.validations.isBankingVerified = value;
    }
    this.forceUpdate();
  };

  handleValidations = (e: any) => {
    e.preventDefault();
    if (this.validate()) {
      this.props.handleClick(this.state);
    } else {
      this.forceUpdate();
    }
  };

  validate = () => {
    this.inlineValidate("bsb", this.state.bsb);
    this.inlineValidate("accountNo", this.state.accountNo);
    this.inlineValidate("accountName", this.state.accountName);
    this.inlineValidate("bankName", this.state.bankName);
    this.validations.isBankingVerified =
      this.props.isOverseasPartner || !!this.state.confirmedBankDetails;

    return (
      this.validations.isBsbValid &&
      this.validations.isAccountValid &&
      this.validations.isAccountNameValid &&
      this.validations.isBankNameValid
    );
  };

  goBack = () => {
    this.props.goBack(this.state);
  };

  render() {
    return (
      <fieldset className="bank-page">
        <legend className="bank-page__title">Your bank details</legend>
        <p className="bank-page__subheading">
          I'm sure getting paid is of the utmost importance to you. Add your business bank details
          below and we'll make it happen.
        </p>
        <div className="row">
          <FormGroup className="col-md-6">
            <Label for="bsb">BSB number</Label>
            <Input
              type="tel"
              id="bsb"
              invalid={!this.validations.isBsbValid}
              placeholder="000-000"
              value={this.state.bsb}
              onChange={this.handleUserInput}
              onBlur={this.handleBlur}
              maxLength={6}
            />
            <FormFeedback>A BSB consists of 6 numbers only</FormFeedback>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="accountNo">Account number</Label>
            <Input
              type="tel"
              id="accountNo"
              invalid={!this.validations.isAccountValid}
              placeholder="Account number"
              value={this.state.accountNo}
              onChange={this.handleUserInput}
              onBlur={this.handleBlur}
            />
            <FormFeedback>Please add a valid account number</FormFeedback>
          </FormGroup>
        </div>
        <div className="row mb-5">
          <FormGroup className="col-md-6">
            <Label for="accountName">Account name</Label>
            <Input
              type="text"
              id="accountName"
              placeholder="Account name"
              value={this.state.accountName}
              onChange={this.handleUserInput}
              invalid={!this.validations.isAccountNameValid}
            />
            <FormFeedback>Please add a valid account name</FormFeedback>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label for="bankName">Bank name</Label>
            <Input
              type="text"
              id="bankName"
              placeholder="Bank name"
              value={this.state.bankName}
              onChange={this.handleUserInput}
              invalid={!this.validations.isBankNameValid}
            />
            <FormFeedback>Please add a valid bank name</FormFeedback>
          </FormGroup>
        </div>

        {this.props.isOverseasPartner && (
          <p>
            Don't have an Australian or New Zealand bank account? Don't worry, finish and submit the
            application and we will arrange another way to get you paid.
          </p>
        )}

        <div className="row">
          <div className="col-12">
            <div className="footer-buttons remove-margin-top" />
          </div>
        </div>

        <div className="row bank-page__confirm-details">
          <div className="col-md-6 col-md-7">
            <FormGroup className="form-check">
              <CustomInput
                className="form-check-input"
                type="checkbox"
                id="confirmedBankDetails"
                onChange={this.handleCheck}
                checked={this.state.confirmedBankDetails}
                label={
                  <span>I confirm the details provided are for my business banking account </span>
                }
              />
            </FormGroup>
          </div>
          <div className="col-md-5">
            <FormGroup className="bank-page__navigation-buttons">
              <Button
                className="float-right prev-btn"
                color="secondary"
                onClick={this.goBack}
                data-testid="previous"
              >
                Previous
              </Button>
              <Button
                className="btn btn-primary float-right"
                color="primary"
                onClick={this.handleValidations}
                data-testid="finish"
              >
                Finish
              </Button>
            </FormGroup>
          </div>
        </div>
      </fieldset>
    );
  }
}

export default BankPage;
