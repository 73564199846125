export interface PhoneFormat {
  type: string;
  // exp: string;
  regex: {
    head: string;
    body: string;
  };
  format: Function;
  minLength: number;
  maxLength: number;
  example?: string;
}
export interface PhoneFormatMap {
  [id: string]: PhoneFormat[];
}

export interface NumberRange {
  min: number;
  max: number;
}

export const getFormattingMap = () => {
  return formattingMap.nz;
};

export const nzMobileRegHead = "^(02[0-9]{1})";
export const nzMobileRegBody = "([0-9]{0,15})$";

// NZ mobile reg /^(02[0-9]{1})([0-9]{0,15})$/g
export const nzMobileReg = new RegExp(
  `${nzMobileRegHead}${nzMobileRegBody}`,
  "g"
);

const formattingMap: PhoneFormatMap = {
  au: [
    {
      type: "mobile",
      regex: {
        head: "^(04[0-9]{2})",
        body: "([0-9]{0,6})$"
      },
      format: (str: string) =>
        `${str.substr(0, 4)} ${str.substr(4, 3)} ${str.substr(7, 3)}`,
      minLength: 10,
      maxLength: 10,
      example: "0413 429 270"
    },
    {
      type: "landline",
      regex: {
        head: "^(0[2,3,7,8]{1})",
        body: "([0-9]{0,8})$"
      },
      format: (str: string) =>
        `(${str.substr(0, 2)}) ${str.substr(2, 4)} ${str.substr(6, 4)}`,
      minLength: 10,
      maxLength: 10,
      example: "(02) 9433 1100"
    },
    {
      type: "landline1300",
      regex: {
        head: "^(13[0]{2})",
        body: "([0-9]{0,6})$"
      },
      format: (str: string) =>
        `${str.substr(0, 4)} ${str.substr(4, 3)} ${str.substr(7, 3)}`,
      minLength: 10,
      maxLength: 10,
      example: "1300 882 867"
    },
    {
      type: "landline1800",
      regex: {
        head: "^(18[0]{2})",
        body: "([0-9]{0,6})$"
      },
      format: (str: string) =>
        `${str.substr(0, 4)} ${str.substr(4, 3)} ${str.substr(7, 3)}`,
      minLength: 10,
      maxLength: 10,
      example: "1800 882 867"
    }
  ],
  nz: [
    {
      type: "mobile",
      regex: {
        head: nzMobileRegHead,
        body: nzMobileRegBody
      },
      format: (str: string) => {
        if (str.length <= 9) {
          return `${str.substr(0, 3)} ${str.substr(3, 3)} ${str.substr(6, 3)}`;
        } else if (str.length === 10) {
          return `${str.substr(0, 2)} ${str.substr(2, 4)} ${str.substr(6, 4)}`;
        } else {
          return `${str.substr(0, 3)} ${str.substr(3, 4)} ${str.substr(7)}`;
        }
      },
      minLength: 5,
      maxLength: 20,
      example: "021 234 567, 02 1234 5678, 021 2345 6789"
    },
    {
      type: "landline",
      regex: {
        head: "^(0[3,4,6,7,9]{1})(?!00)",
        body: "([0-9]{0,15})$"
      },
      format: (str: string) =>
        `(${str.substr(0, 2)}) ${str.substr(2, 3)} ${str.substr(5)}`,
      minLength: 5,
      maxLength: 20,
      example: "(04) 933 1100"
    },
    {
      type: "tollfree0508",
      regex: {
        head: "^(0508)",
        body: "([0-9]{0,15})"
      },
      format: (str: string) =>
        `${str.substr(0, 4)} ${str.substr(4, 3)} ${str.substr(7)}`,
      minLength: 5,
      maxLength: 20,
      example: "0508 133 1100"
    },
    {
      type: "tollfree08xx",
      regex: {
        head: "^(08[0-9]{0,2})",
        body: "([0-9]{0,15})"
      },
      format: (str: string) =>
        `${str.substr(0, 4)} ${str.substr(4, 3)} ${str.substr(7)}`,
      minLength: 5,
      maxLength: 20,
      example: "0800 133 1100"
    },
    {
      type: "premium09xx",
      regex: {
        head: "^(0900)",
        body: "([0-9]{0,15})"
      },
      format: (str: string) => `${str.substr(0, 4)} ${str.substr(4)}`,
      minLength: 5,
      maxLength: 20,
      example: "0900 133110"
    }
  ]
};
